import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as CustomThemeProvider, useTheme } from './contexts/ThemeContext';
import Layout from './components/Layout';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ChatInterface from './pages/ChatInterface';
import AdminDashboard from './pages/AdminDashboard';
import AssistantChatInterface from './pages/AssistantChatInterface';
import PrivateRoute from './components/PrivateRoute';

const AppContent = () => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout>
                <Dashboard />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Layout>
                <Dashboard />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/chat/:botId"
          element={
            <PrivateRoute>
              <Layout>
                <ChatInterface />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/assistant-chat"
          element={
            <PrivateRoute>
              <Layout>
                <AssistantChatInterface />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/assistant-chat/:chatId"
          element={
            <PrivateRoute>
              <Layout>
                <AssistantChatInterface />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/*"
          element={
            <PrivateRoute adminOnly>
              <Layout>
                <AdminDashboard />
              </Layout>
            </PrivateRoute>
          }
        />
      </Routes>
    </ThemeProvider>
  );
};

function App() {
  return (
    <CustomThemeProvider>
      <AppContent />
    </CustomThemeProvider>
  );
}

export default App;
