import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const Footer: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) return null;

  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 4,
        mt: 'auto',
        bgcolor: 'background.paper',
        borderTop: '1px solid',
        borderColor: 'divider',
        textAlign: 'center'
      }}
    >
      <Typography variant="body2" color="text.secondary">
        © 2024 MMP Construction. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
