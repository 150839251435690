import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import UserManagement from './UserManagement';
import Analytics from './Analytics';
import UserChatHistory from './UserChatHistory';
import AssistantManagement from './AssistantManagement';
import AdminNav from './AdminNav';

const AdminDashboard: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ 
      width: '100%', 
      height: '100%', 
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      pb: isMobile ? '56px' : 0, // Account for bottom navigation on mobile
    }}>
      <AdminNav />
      <Box sx={{ 
        flex: 1,
        overflow: 'auto', 
        p: 3,
      }}>
        <Routes>
          <Route path="/" element={<Analytics />} />
          <Route path="/users" element={<UserManagement />} />
          <Route path="/users/:userId/chats" element={<UserChatHistory />} />
          <Route path="/assistants" element={<AssistantManagement />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
