import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, IconButton, Breadcrumbs, Link, CircularProgress, Button, Alert } from '@mui/material';
import { Folder as FolderIcon, ArrowBack as ArrowBackIcon, Description as FileIcon } from '@mui/icons-material';
import { sharepointApi, SharePointFolder, SharePointFile } from '../services/sharepoint';

interface SharePointBrowserProps {
  onFolderSelect?: (path: string) => void;
  onCancel?: () => void;
  showFiles?: boolean;
}

const SUPPORTED_FILE_TYPES = [
  '.c', '.cs', '.cpp', '.doc', '.docx', '.html', '.java', 
  '.json', '.md', '.pdf', '.php', '.pptx', '.py', '.rb', 
  '.tex', '.txt', '.css', '.js', '.sh', '.ts'
];

export const SharePointBrowser: React.FC<SharePointBrowserProps> = ({ onFolderSelect, onCancel, showFiles = false }) => {
  const [currentPath, setCurrentPath] = useState<string>('');
  const [folders, setFolders] = useState<SharePointFolder[]>([]);
  const [files, setFiles] = useState<SharePointFile[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectLoading, setSelectLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [pathHistory, setPathHistory] = useState<string[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);

  // Load folders and optionally files at the current path
  const loadContent = async (path: string) => {
    try {
      setLoading(true);
      setError(null);

      const [folderList, fileList] = await Promise.all([
        sharepointApi.listFolders(path || '_'),
        showFiles ? sharepointApi.listFiles(path || '_') : Promise.resolve([])
      ]);

      setFolders(folderList);
      setFiles(fileList);

      // Update breadcrumbs
      const parts = path ? path.split('/').filter(Boolean) : [];
      setBreadcrumbs(['Root', ...parts]);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load content');
    } finally {
      setLoading(false);
    }
  };

  // Initialize with root path
  useEffect(() => {
    const initializeBrowser = async () => {
      try {
        const rootPath = await sharepointApi.getLibraryRoot();
        setCurrentPath(rootPath);
        loadContent('');
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to initialize browser');
      }
    };

    initializeBrowser();
  }, []);

  // Navigate to a folder
  const handleFolderClick = (folder: SharePointFolder) => {
    setPathHistory([...pathHistory, currentPath]);
    setCurrentPath(folder.path);
    const relativePath = folder.path.split('/Dcouments_latest/')[1] || '';
    loadContent(relativePath);
  };

  // Navigate back
  const handleBack = () => {
    if (pathHistory.length > 0) {
      const previousPath = pathHistory[pathHistory.length - 1];
      const newHistory = pathHistory.slice(0, -1);
      setPathHistory(newHistory);
      setCurrentPath(previousPath);
      const relativePath = previousPath.split('/Dcouments_latest/')[1] || '';
      loadContent(relativePath);
    }
  };

  // Navigate using breadcrumbs
  const handleBreadcrumbClick = (index: number) => {
    if (index === 0) {
      // Root
      setCurrentPath('');
      setPathHistory([]);
      loadContent('');
    } else {
      const newPath = breadcrumbs.slice(1, index + 1).join('/');
      setCurrentPath(newPath);
      setPathHistory(pathHistory.slice(0, index));
      loadContent(newPath);
    }
  };

  // Handle folder selection with loading state
  const handleSelect = async () => {
    if (onFolderSelect) {
      setSelectLoading(true);
      try {
        await onFolderSelect(currentPath);
      } finally {
        setSelectLoading(false);
      }
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 800, margin: '0 auto', p: 2 }}>
      {/* Header */}
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
        {pathHistory.length > 0 && (
          <IconButton onClick={handleBack} size="small" disabled={loading || selectLoading}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Breadcrumbs>
          {breadcrumbs.map((crumb, index) => (
            <Link
              key={index}
              component="button"
              onClick={() => handleBreadcrumbClick(index)}
              color="inherit"
              underline="hover"
              sx={{ cursor: 'pointer' }}
              disabled={loading || selectLoading}
            >
              {crumb}
            </Link>
          ))}
        </Breadcrumbs>
      </Box>

      {/* Supported File Types Alert */}
      <Alert severity="info" sx={{ mb: 2 }}>
        File types supported: {SUPPORTED_FILE_TYPES.join(', ')}
      </Alert>

      {/* Error message */}
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {/* Loading indicator */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Content */}
          <Box sx={{ bgcolor: 'background.paper', borderRadius: 1, mb: 2 }}>
            <List>
              {folders.map((folder) => (
                <ListItem
                  key={folder.path}
                  button
                  onClick={() => handleFolderClick(folder)}
                  disabled={selectLoading}
                  sx={{
                    '&:hover': {
                      bgcolor: 'action.hover',
                    },
                  }}
                >
                  <ListItemIcon>
                    <FolderIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary={folder.name}
                    secondary={new Date(folder.modified).toLocaleDateString()}
                  />
                </ListItem>
              ))}

              {showFiles && files.map((file) => (
                <ListItem
                  key={file.path}
                  sx={{
                    opacity: 0.8,
                  }}
                >
                  <ListItemIcon>
                    <FileIcon />
                  </ListItemIcon>
                  <ListItemText 
                    primary={file.name}
                    secondary={`${(file.size / 1024).toFixed(2)} KB • ${new Date(file.modified).toLocaleDateString()}`}
                  />
                </ListItem>
              ))}

              {folders.length === 0 && (!showFiles || files.length === 0) && (
                <ListItem>
                  <ListItemText 
                    primary="This folder is empty"
                    sx={{ textAlign: 'center', color: 'text.secondary' }}
                  />
                </ListItem>
              )}
            </List>
          </Box>

          {/* Actions */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            {onCancel && (
              <Button 
                onClick={onCancel}
                variant="outlined"
                color="inherit"
                disabled={selectLoading}
              >
                Cancel
              </Button>
            )}
            {onFolderSelect && (
              <Button 
                onClick={handleSelect}
                variant="contained"
                disabled={loading || selectLoading}
                startIcon={selectLoading && <CircularProgress size={20} color="inherit" />}
              >
                {selectLoading ? 'Calculating...' : 'Select This Folder'}
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
