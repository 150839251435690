import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  useTheme,
  Stack,
  Tooltip,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Divider,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { RootState } from '../store';
import { useTheme as useCustomTheme } from '../contexts/ThemeContext';

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isDarkMode, toggleTheme } = useCustomTheme();
  const user = useSelector((state: RootState) => state.auth.user);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [userMenuAnchor, setUserMenuAnchor] = useState<null | HTMLElement>(null);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
    handleMobileMenuClose();
    handleUserMenuClose();
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchor(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchor(null);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    handleMobileMenuClose();
    handleUserMenuClose();
  };

  const mobileDrawer = (
    <Drawer
      anchor="left"
      open={mobileMenuOpen}
      onClose={handleMobileMenuClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: 280,
          boxSizing: 'border-box',
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box
          component="img"
          src="/logo.png"
          alt="BOB | MMP Construction"
          sx={{
            height: 40,
            mb: 2,
          }}
        />
        <Typography variant="h6" color="text.primary" gutterBottom>
          BOB | MMP Construction
        </Typography>
      </Box>
      <Divider />
      <List>
        <ListItem button onClick={() => handleNavigation('/dashboard')}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        {user?.role === 'admin' && (
          <ListItem button onClick={() => handleNavigation('/admin')}>
            <ListItemIcon>
              <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemText 
            primary={user?.full_name}
            secondary={user?.email}
          />
        </ListItem>
        <ListItem button onClick={toggleTheme}>
          <ListItemIcon>
            {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </ListItemIcon>
          <ListItemText primary="Toggle Theme" />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
      </List>
    </Drawer>
  );

  return (
    <AppBar 
      position="sticky" 
      elevation={0}
      sx={{
        bgcolor: 'background.paper',
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      <Toolbar>
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleMobileMenuToggle}
              sx={{ mr: 2, color: theme.palette.text.primary }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              component="img"
              src="/logo.png"
              alt="BOB | MMP Construction"
              sx={{
                height: 40,
              }}
            />
            {mobileDrawer}
          </>
        ) : (
          <>
            <Box
              component="img"
              src="/logo.png"
              alt="BOB | MMP Construction"
              sx={{
                height: 40,
                mr: 2,
              }}
            />
            <Typography 
              variant="h6" 
              component="div" 
              sx={{ 
                flexGrow: 0,
                color: theme.palette.text.primary,
                mr: 4,
              }}
            >
              BOB | MMP Construction
            </Typography>

            <Button
              color="inherit"
              startIcon={<DashboardIcon />}
              onClick={() => handleNavigation('/dashboard')}
              sx={{
                color: theme.palette.text.primary,
                mr: 2,
              }}
            >
              Dashboard
            </Button>

            {user?.role === 'admin' && (
              <Button
                color="inherit"
                startIcon={<AdminPanelSettingsIcon />}
                onClick={() => handleNavigation('/admin')}
                sx={{
                  color: theme.palette.text.primary,
                  mr: 2,
                }}
              >
                Admin
              </Button>
            )}

            <Box sx={{ flexGrow: 1 }} />

            <Stack direction="row" spacing={2} alignItems="center">
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.text.primary,
                }}
              >
                {user?.full_name}
              </Typography>

              <Tooltip title="Toggle theme">
                <IconButton
                  onClick={toggleTheme}
                  color="inherit"
                  sx={{
                    color: theme.palette.text.primary,
                  }}
                >
                  {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
              </Tooltip>

              <Tooltip title="Account">
                <IconButton
                  color="inherit"
                  onClick={handleUserMenuOpen}
                  sx={{
                    color: theme.palette.text.primary,
                  }}
                >
                  <AccountCircleIcon />
                </IconButton>
              </Tooltip>
            </Stack>

            <Menu
              anchorEl={userMenuAnchor}
              open={Boolean(userMenuAnchor)}
              onClose={handleUserMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem disabled>
                <Typography variant="body2" color="text.secondary">
                  {user?.email}
                </Typography>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Sign Out</ListItemText>
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
