import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ParticleBackground from './ParticleBackground';
import Footer from './Footer';
import Navbar from './Navbar';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.default',
        color: 'text.primary',
        transition: theme.transitions.create(['background-color', 'color'], {
          duration: theme.transitions.duration.standard,
        }),
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <ParticleBackground />
      <Navbar />

      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          width: '100%',
          maxWidth: '100vw',
          margin: '0 auto',
          p: { xs: 2, sm: 3 },
        }}
      >
        {children}
      </Box>

      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default Layout;
