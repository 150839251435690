import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Key as KeyIcon,
  History as HistoryIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import apiService from '../../services/api';

interface User {
  id: string;
  email: string;
  full_name: string;
  role: string;
  assigned_assistants: string[];  // Changed from assigned_sites
  is_active: boolean;
}

interface Assistant {  // Added interface for assistants
  id: string;
  name: string;
}

const UserManagement: React.FC = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [assistants, setAssistants] = useState<Assistant[]>([]);  // Added state for assistants
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [newPassword, setNewPassword] = useState('');
  const [newUser, setNewUser] = useState({
    email: '',
    password: '',
    full_name: '',
    role: 'user',
    assigned_assistants: [] as string[],  // Changed from assigned_sites
  });

  useEffect(() => {
    fetchUsers();
    fetchAssistants();  // Added fetch for assistants
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await apiService.admin.getUsers();
      setUsers(response.users);
    } catch (error: any) {
      console.error('Error fetching users:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchAssistants = async () => {  // Added function to fetch assistants
    try {
      const response = await apiService.assistants.list();
      setAssistants(response);
    } catch (error: any) {
      console.error('Error fetching assistants:', error);
    }
  };

  const handleCreateUser = async () => {
    try {
      setLoading(true);
      await apiService.admin.createUser(newUser);
      setSuccess('User created successfully');
      setOpenCreate(false);
      setNewUser({
        email: '',
        password: '',
        full_name: '',
        role: 'user',
        assigned_assistants: [],  // Changed from assigned_sites
      });
      await fetchUsers();
    } catch (error: any) {
      console.error('Error creating user:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUser = async () => {
    if (!selectedUser) return;

    try {
      setLoading(true);
      await apiService.admin.updateUser(selectedUser.id, {
        full_name: selectedUser.full_name,
        role: selectedUser.role,
        assigned_assistants: selectedUser.assigned_assistants,  // Changed from assigned_sites
        is_active: selectedUser.is_active,
      });
      setSuccess('User updated successfully');
      setOpenEdit(false);
      await fetchUsers();
    } catch (error: any) {
      console.error('Error updating user:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (!selectedUser) return;

    try {
      setLoading(true);
      await apiService.admin.changeUserPassword(selectedUser.id, newPassword);
      setSuccess('Password changed successfully');
      setOpenPassword(false);
      setNewPassword('');
    } catch (error: any) {
      console.error('Error changing password:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAssistantChange = (event: SelectChangeEvent<string[]>) => {  // Changed from handleSiteChange
    const value = event.target.value;
    if (selectedUser) {
      setSelectedUser({
        ...selectedUser,
        assigned_assistants: typeof value === 'string' ? value.split(',') : value,  // Changed from assigned_sites
      });
    }
  };

  if (loading && users.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5">User Management</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenCreate(true)}
        >
          Create User
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Assigned Assistants</TableCell>  {/* Changed from Sites to Assistants */}
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.full_name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>
                  {user.assigned_assistants.map((assistantId) => {  // Changed from assigned_sites
                    const assistant = assistants.find(a => a.id === assistantId);
                    return (
                      <Chip
                        key={assistantId}
                        label={assistant?.name || assistantId}
                        size="small"
                        sx={{ mr: 0.5, mb: 0.5 }}
                      />
                    );
                  })}
                </TableCell>
                <TableCell>
                  <Chip
                    label={user.is_active ? 'Active' : 'Inactive'}
                    color={user.is_active ? 'success' : 'error'}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit User">
                    <IconButton
                      onClick={() => {
                        setSelectedUser(user);
                        setOpenEdit(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Change Password">
                    <IconButton
                      onClick={() => {
                        setSelectedUser(user);
                        setOpenPassword(true);
                      }}
                    >
                      <KeyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View Chat History">
                    <IconButton onClick={() => navigate(`/admin/users/${user.id}/chats`)}>
                      <HistoryIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Create User Dialog */}
      <Dialog open={openCreate} onClose={() => setOpenCreate(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={newUser.password}
            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Full Name"
            fullWidth
            value={newUser.full_name}
            onChange={(e) => setNewUser({ ...newUser, full_name: e.target.value })}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Role</InputLabel>
            <Select
              value={newUser.role}
              onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
              label="Role"
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Assigned Assistants</InputLabel>  {/* Changed from Sites to Assistants */}
            <Select
              multiple
              value={newUser.assigned_assistants}  // Changed from assigned_sites
              onChange={(e: SelectChangeEvent<string[]>) => {
                const value = e.target.value;
                setNewUser({
                  ...newUser,
                  assigned_assistants: typeof value === 'string' ? value.split(',') : value,  // Changed from assigned_sites
                });
              }}
              label="Assigned Assistants"  // Changed from Sites to Assistants
            >
              {assistants.map((assistant) => (  // Changed from sites to assistants
                <MenuItem key={assistant.id} value={assistant.id}>
                  {assistant.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreate(false)}>Cancel</Button>
          <Button onClick={handleCreateUser} variant="contained" color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit User Dialog */}
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          {selectedUser && (
            <>
              <TextField
                autoFocus
                margin="dense"
                label="Full Name"
                fullWidth
                value={selectedUser.full_name}
                onChange={(e) =>
                  setSelectedUser({ ...selectedUser, full_name: e.target.value })
                }
              />
              <FormControl fullWidth margin="dense">
                <InputLabel>Role</InputLabel>
                <Select
                  value={selectedUser.role}
                  onChange={(e) =>
                    setSelectedUser({ ...selectedUser, role: e.target.value })
                  }
                  label="Role"
                >
                  <MenuItem value="user">User</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel>Assigned Assistants</InputLabel>  {/* Changed from Sites to Assistants */}
                <Select
                  multiple
                  value={selectedUser.assigned_assistants}  // Changed from assigned_sites
                  onChange={handleAssistantChange}  // Changed from handleSiteChange
                  label="Assigned Assistants"  // Changed from Sites to Assistants
                >
                  {assistants.map((assistant) => (  // Changed from sites to assistants
                    <MenuItem key={assistant.id} value={assistant.id}>
                      {assistant.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel>Status</InputLabel>
                <Select
                  value={selectedUser.is_active ? 'active' : 'inactive'}
                  onChange={(e) =>
                    setSelectedUser({
                      ...selectedUser,
                      is_active: e.target.value === 'active',
                    })
                  }
                  label="Status"
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEdit(false)}>Cancel</Button>
          <Button onClick={handleUpdateUser} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Change Password Dialog */}
      <Dialog open={openPassword} onClose={() => setOpenPassword(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Password"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPassword(false)}>Cancel</Button>
          <Button onClick={handleChangePassword} variant="contained" color="primary">
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserManagement;
