import React from 'react';
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import { Site } from '../types/chat';

interface SiteSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  onSiteSelect: (siteId: string) => void;
  assignedSites: string[];
  sites: Site[];
}

const SiteSelectionDialog: React.FC<SiteSelectionDialogProps> = ({
  open,
  onClose,
  onSiteSelect,
  assignedSites,
  sites,
}) => {
  const availableSites = sites.filter(site => 
    assignedSites.includes(site.name)
  );

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 3,
        }
      }}
    >
      <DialogTitle sx={{ 
        m: 0, 
        p: 3, 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Typography variant="h6">Select Construction Site</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: 'text.secondary',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <List sx={{ pt: 0 }}>
        {availableSites.map((site) => (
          <ListItem key={site.id} disablePadding>
            <ListItemButton
              onClick={() => {
                onSiteSelect(site.id);
                onClose();
              }}
              sx={{ 
                py: 2,
                px: 3,
                '&:hover': {
                  bgcolor: 'action.hover',
                }
              }}
            >
              <ListItemIcon>
                <BusinessIcon color="primary" />
              </ListItemIcon>
              <Box>
                <ListItemText 
                  primary={site.name}
                  secondary={site.description}
                  primaryTypographyProps={{
                    fontWeight: 500,
                    gutterBottom: true,
                  }}
                  secondaryTypographyProps={{
                    variant: 'body2',
                  }}
                />
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ display: 'block', mt: 0.5 }}
                >
                  {site.location}
                </Typography>
              </Box>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default SiteSelectionDialog;
