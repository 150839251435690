import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  TextField,
  IconButton,
  List,
  ListItemText,
  ListItemButton,
  Divider,
  Button,
  CircularProgress,
  Alert,
  Container,
  useTheme,
  useMediaQuery,
  Drawer,
} from '@mui/material';
import {
  Send as SendIcon,
  Add as AddIcon,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';
import { Message, ChatHistoryItem } from '../types/chat';
import apiService from '../services/api';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';

// Add type definitions for ReactMarkdown components
type CodeProps = {
  className?: string;
  children?: React.ReactNode;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

type MarkdownComponents = Components & {
  p: React.ComponentType<{ children?: React.ReactNode }>;
  a: React.ComponentType<{ href?: string; children?: React.ReactNode }>;
  code: React.ComponentType<CodeProps>;
  h1: React.ComponentType<{ children?: React.ReactNode }>;
  h2: React.ComponentType<{ children?: React.ReactNode }>;
  h3: React.ComponentType<{ children?: React.ReactNode }>;
  ul: React.ComponentType<{ children?: React.ReactNode }>;
  ol: React.ComponentType<{ children?: React.ReactNode }>;
  li: React.ComponentType<{ children?: React.ReactNode }>;
  blockquote: React.ComponentType<{ children?: React.ReactNode }>;
  table: React.ComponentType<{ children?: React.ReactNode }>;
  th: React.ComponentType<{ children?: React.ReactNode }>;
  td: React.ComponentType<{ children?: React.ReactNode }>;
};

const DRAWER_WIDTH = 300;

const ChatInterface: React.FC = () => {
  const { botId } = useParams<{ botId: string }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChat, setSelectedChat] = useState<string | null>(null);
  const [chats, setChats] = useState<ChatHistoryItem[]>([]);
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const loadChats = useCallback(async () => {
    try {
      setIsLoadingChats(true);
      setError(null);
      const response = await apiService.chat.listChats();
      const filteredChats = response.chats
        .filter((chat: ChatHistoryItem) => chat.botType === botId)
        .sort((a: ChatHistoryItem, b: ChatHistoryItem) => 
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      setChats(filteredChats);
    } catch (err: any) {
      setError('Failed to load chat history');
      console.error('Error loading chats:', err);
    } finally {
      setIsLoadingChats(false);
    }
  }, [botId]);

  useEffect(() => {
    loadChats();
  }, [loadChats]);

  useEffect(() => {
    if (selectedChat) {
      loadChat(selectedChat);
    }
  }, [selectedChat]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const loadChat = async (chatId: string) => {
    try {
      setError(null);
      const chat = await apiService.chat.getChat(chatId);
      setMessages(chat.messages || []);
    } catch (err: any) {
      setError('Failed to load chat');
      console.error('Error loading chat:', err);
    }
  };

  const handleNewChat = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const result = await apiService.chat.createChat(botId || 'general');
      setSelectedChat(result.id);
      setMessages([]);
      if (isMobile) {
        setDrawerOpen(false);
      }
      await loadChats();
    } catch (err: any) {
      setError('Failed to create new chat');
      console.error('Error creating new chat:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectChat = (chat: ChatHistoryItem) => {
    setSelectedChat(chat.id);
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    if (!selectedChat) {
      try {
        setIsLoading(true);
        setError(null);
        const result = await apiService.chat.createChat(botId || 'general');
        setSelectedChat(result.id);
        const response = await apiService.chat.sendMessage(result.id, newMessage);
        setMessages(prev => [...prev, ...response.messages]);
        setNewMessage('');
        await loadChats();
      } catch (err: any) {
        setError('Failed to send message');
        console.error('Error sending message:', err);
      } finally {
        setIsLoading(false);
      }
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      const response = await apiService.chat.sendMessage(selectedChat, newMessage);
      setMessages(prev => [...prev, ...response.messages]);
      setNewMessage('');
      await loadChats();
    } catch (err: any) {
      setError('Failed to send message');
      console.error('Error sending message:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const drawer = (
    <Box
      sx={{
        width: DRAWER_WIDTH,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box p={1} display="flex" alignItems="center" gap={1}>
        <Button
          fullWidth
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleNewChat}
          size="small"
        >
          New Chat
        </Button>
        {isMobile && (
          <IconButton onClick={() => setDrawerOpen(false)} size="small">
            <ChevronLeftIcon />
          </IconButton>
        )}
      </Box>
      <Divider />
      <List sx={{ flexGrow: 1, overflow: 'auto' }}>
        {chats.map((chat) => (
          <React.Fragment key={chat.id}>
            <ListItemButton
              selected={selectedChat === chat.id}
              onClick={() => handleSelectChat(chat)}
              sx={{ py: 1 }}
            >
              <ListItemText
                primary={chat.title}
                secondary={new Date(chat.createdAt).toLocaleString()}
                primaryTypographyProps={{ fontSize: '0.9rem' }}
                secondaryTypographyProps={{ fontSize: '0.8rem' }}
              />
            </ListItemButton>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <Container 
      maxWidth={false} 
      disableGutters 
      sx={{ 
        height: 'calc(100vh - 64px - 50px)', // Subtract navbar (64px) and footer (50px) heights
        p: 0, // Remove padding
      }}
    >
      <Paper 
        elevation={3} 
        sx={{ 
          display: 'flex',
          height: '100%',
          width: '100%', // Take full width
          overflow: 'hidden',
          borderRadius: 0, // Remove border radius for full-width look
          position: 'relative',
        }}
      >
        {/* Drawer for mobile */}
        {isMobile ? (
          <Drawer
            variant="temporary"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            sx={{
              '& .MuiDrawer-paper': {
                width: DRAWER_WIDTH,
                position: 'relative',
                height: '100%',
              },
            }}
          >
            {drawer}
          </Drawer>
        ) : (
          // Permanent drawer for desktop
          <Box
            sx={{
              width: drawerOpen ? DRAWER_WIDTH : 0,
              flexShrink: 0,
              overflow: 'hidden',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }}
          >
            {drawer}
          </Box>
        )}

        {/* Main Chat Area */}
        <Box 
          sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            flexDirection: 'column',
            height: '100%',
            position: 'relative',
            bgcolor: 'background.paper',
          }}
        >
          {/* Toggle Button */}
          {!isMobile && (
            <IconButton
              onClick={() => setDrawerOpen(!drawerOpen)}
              sx={{
                position: 'absolute',
                left: 8,
                top: 8,
                zIndex: 1,
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'action.hover',
                },
                boxShadow: 1,
              }}
              size="small"
            >
              {drawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
          )}

          {!selectedChat ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              {isMobile && !drawerOpen && (
                <IconButton
                  onClick={() => setDrawerOpen(true)}
                  sx={{ position: 'absolute', left: 8, top: 8 }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography variant="h6" color="textSecondary">
                Select a chat or start a new one
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleNewChat}
                sx={{ mt: 2 }}
              >
                New Chat
              </Button>
            </Box>
          ) : (
            <>
              {/* Messages Container */}
              <Box 
                sx={{ 
                  flexGrow: 1, 
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  p: 2,
                  pb: 10,
                  pt: isMobile ? 6 : 2,
                }}
              >
                {isMobile && !drawerOpen && (
                  <IconButton
                    onClick={() => setDrawerOpen(true)}
                    sx={{
                      position: 'absolute',
                      left: 8,
                      top: 8,
                      zIndex: 1,
                      bgcolor: 'background.paper',
                      '&:hover': {
                        bgcolor: 'action.hover',
                      },
                      boxShadow: 1,
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
                {messages.map((message, index) => (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent={message.role === 'user' ? 'flex-end' : 'flex-start'}
                  >
                    <Paper
                      sx={{
                        p: 1.5,
                        maxWidth: isMobile ? '85%' : '70%',
                        bgcolor: message.role === 'user' ? 'primary.main' : theme.palette.mode === 'dark' ? 'grey.800' : 'grey.100',
                        color: message.role === 'user' ? 'primary.contrastText' : 'text.primary',
                        '& .markdown-content': {
                          '& p': { margin: '0.5em 0' },
                          '& p:first-of-type': { marginTop: 0 },
                          '& p:last-of-type': { marginBottom: 0 },
                          '& pre': {
                            padding: '0.5em',
                            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.05)',
                            borderRadius: '4px',
                            overflowX: 'auto',
                          },
                          '& code': {
                            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.05)',
                            padding: '0.2em 0.4em',
                            borderRadius: '3px',
                            fontSize: '85%',
                          },
                          '& ul, & ol': {
                            marginTop: '0.5em',
                            marginBottom: '0.5em',
                            paddingLeft: '1.5em',
                          },
                          '& table': {
                            borderCollapse: 'collapse',
                            width: '100%',
                            margin: '0.5em 0',
                          },
                          '& th, & td': {
                            border: `1px solid ${theme.palette.divider}`,
                            padding: '0.4em',
                          },
                          '& blockquote': {
                            margin: '0.5em 0',
                            paddingLeft: '1em',
                            borderLeft: `3px solid ${theme.palette.divider}`,
                            color: theme.palette.text.secondary,
                          },
                        },
                      }}
                    >
                      <Box className="markdown-content">
                        {message.role === 'user' ? (
                          <Typography fontSize="0.9rem">{message.content}</Typography>
                        ) : (
                          <ReactMarkdown 
                            remarkPlugins={[remarkGfm]}
                            components={{
                              // Handle paragraphs
                              p: ({children}) => (
                                <Typography variant="body1" fontSize="0.9rem" gutterBottom>
                                  {children}
                                </Typography>
                              ),
                              // Handle links
                              a: ({href, children}) => (
                                <Typography
                                  component="a"
                                  href={href}
                                  fontSize="0.9rem"
                                  color="primary"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {children}
                                </Typography>
                              ),
                              // Handle code blocks
                              code: ({className, children}) => {
                                const match = /language-(\w+)/.exec(className || '');
                                const isInline = !match;
                                return isInline ? (
                                  <code
                                    style={{
                                      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.05)',
                                      padding: '0.2em 0.4em',
                                      borderRadius: '3px',
                                      fontSize: '85%',
                                      fontFamily: 'monospace',
                                    }}
                                  >
                                    {children}
                                  </code>
                                ) : (
                                  <pre
                                    style={{
                                      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.05)',
                                      padding: '0.5em',
                                      borderRadius: '3px',
                                      fontSize: '85%',
                                      overflowX: 'auto',
                                      fontFamily: 'monospace',
                                      whiteSpace: 'pre-wrap',
                                      wordBreak: 'break-word',
                                      margin: '0.5em 0',
                                    }}
                                  >
                                    <code>{children}</code>
                                  </pre>
                                );
                              },
                              // Handle headings
                              h1: ({children}) => (
                                <Typography variant="h5" gutterBottom>
                                  {children}
                                </Typography>
                              ),
                              h2: ({children}) => (
                                <Typography variant="h6" gutterBottom>
                                  {children}
                                </Typography>
                              ),
                              h3: ({children}) => (
                                <Typography variant="subtitle1" gutterBottom>
                                  {children}
                                </Typography>
                              ),
                              // Handle lists
                              ul: ({children}) => (
                                <Box component="ul" sx={{ pl: 2, my: 1 }}>
                                  {children}
                                </Box>
                              ),
                              ol: ({children}) => (
                                <Box component="ol" sx={{ pl: 2, my: 1 }}>
                                  {children}
                                </Box>
                              ),
                              li: ({children}) => (
                                <Box component="li" sx={{ fontSize: '0.9rem' }}>
                                  {children}
                                </Box>
                              ),
                              // Handle blockquotes
                              blockquote: ({children}) => (
                                <Box
                                  component="blockquote"
                                  sx={{
                                    borderLeft: `3px solid ${theme.palette.divider}`,
                                    pl: 2,
                                    my: 1,
                                    color: theme.palette.text.secondary,
                                  }}
                                >
                                  {children}
                                </Box>
                              ),
                              // Handle tables
                              table: ({children}) => (
                                <Box
                                  component="table"
                                  sx={{
                                    width: '100%',
                                    borderCollapse: 'collapse',
                                    my: 1,
                                  }}
                                >
                                  {children}
                                </Box>
                              ),
                              th: ({children}) => (
                                <Box
                                  component="th"
                                  sx={{
                                    border: `1px solid ${theme.palette.divider}`,
                                    p: 1,
                                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.02)',
                                  }}
                                >
                                  {children}
                                </Box>
                              ),
                              td: ({children}) => (
                                <Box
                                  component="td"
                                  sx={{
                                    border: `1px solid ${theme.palette.divider}`,
                                    p: 1,
                                  }}
                                >
                                  {children}
                                </Box>
                              ),
                            }}
                          >
                            {message.content}
                          </ReactMarkdown>
                        )}
                      </Box>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          display: 'block',
                          mt: 0.5,
                          color: message.role === 'user' ? 'primary.contrastText' : 'text.secondary',
                        }}
                      >
                        {new Date(message.timestamp).toLocaleString()}
                      </Typography>
                    </Paper>
                  </Box>
                ))}
                <div ref={messagesEndRef} />
              </Box>

              {/* Message Input - Fixed at bottom */}
              <Box 
                sx={{ 
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  p: 2,
                  bgcolor: 'background.paper',
                  borderTop: 1,
                  borderColor: 'divider',
                }}
              >
                <Box display="flex" gap={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Type your message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSendMessage();
                      }
                    }}
                    disabled={isLoading}
                    multiline
                    maxRows={3}
                    size="small"
                    sx={{ 
                      '& .MuiOutlinedInput-root': {
                        padding: '8px',
                      }
                    }}
                  />
                  <IconButton
                    color="primary"
                    onClick={handleSendMessage}
                    disabled={isLoading || !newMessage.trim()}
                    size="small"
                  >
                    {isLoading ? <CircularProgress size={20} /> : <SendIcon />}
                  </IconButton>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default ChatInterface;
