import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  Tabs,
  Tab,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import apiService from '../../services/api';

interface ChatMessage {
  role: string;
  content: string;
  timestamp: string;
}

interface BaseChat {
  id: string;
  title: string;
  messages: ChatMessage[];
  createdAt: string;
}

interface GeneralChat extends BaseChat {
  type: 'general';
}

interface AssistantChat extends BaseChat {
  type: 'assistant';
  assistantName: string;
}

type Chat = GeneralChat | AssistantChat;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`chat-history-tabpanel-${index}`}
      aria-labelledby={`chat-history-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const UserChatHistory: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [chats, setChats] = useState<Chat[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (userId) {
      loadChats();
    }
  }, [userId]);

  const loadChats = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Load general chats
      const generalResponse = await apiService.admin.getUserChats(userId!);
      const generalChats: GeneralChat[] = (generalResponse.chats || []).map((chat: BaseChat) => ({ 
        ...chat, 
        type: 'general' as const 
      }));

      // Load assistant chats
      const assistantResponse = await apiService.assistants.listChats();
      const userAssistantChats = (assistantResponse.chats || [])
        .filter((chat: any) => chat.userId === userId)
        .map((chat: BaseChat & { assistantName: string }) => ({ 
          ...chat, 
          type: 'assistant' as const 
        }));

      // Sort all chats by createdAt in descending order (newest first)
      const sortedChats = [...generalChats, ...userAssistantChats].sort((a, b) => 
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      
      setChats(sortedChats);
      setError(null);
    } catch (err: any) {
      console.error('Error loading chats:', err);
      setError('Failed to load chat history');
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString();
  };

  const handleAccordionChange = (chatId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? chatId : false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  const generalChats = chats.filter((chat): chat is GeneralChat => chat.type === 'general');
  const assistantChats = chats.filter((chat): chat is AssistantChat => chat.type === 'assistant');

  const renderChatList = (chatList: Chat[]) => {
    if (chatList.length === 0) {
      return <Alert severity="info">No chat history found.</Alert>;
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {chatList.map((chat) => (
          <Accordion
            key={chat.id}
            expanded={expanded === chat.id}
            onChange={handleAccordionChange(chat.id)}
            sx={{
              '&:before': { display: 'none' },
              borderRadius: '8px',
              overflow: 'hidden',
              boxShadow: 2,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                '& .MuiAccordionSummary-expandIconWrapper': {
                  color: 'primary.contrastText',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography variant="h6">{chat.title}</Typography>
                {chat.type === 'assistant' && (
                  <Typography variant="subtitle2">
                    Assistant: {chat.assistantName}
                  </Typography>
                )}
                <Typography variant="caption">
                  Created: {formatDate(chat.createdAt)}
                </Typography>
                <Typography variant="caption">
                  Messages: {chat.messages?.length || 0}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Paper elevation={0} sx={{ bgcolor: 'background.default' }}>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  {(chat.messages || []).map((message, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        alignItems="flex-start"
                        sx={{
                          flexDirection: 'column',
                          bgcolor: message.role === 'user' ? 'action.hover' : 'background.paper',
                        }}
                      >
                        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Chip
                            label={message.role.toUpperCase()}
                            size="small"
                            color={message.role === 'user' ? 'primary' : 'default'}
                            sx={{ mr: 1 }}
                          />
                          <Typography variant="caption" color="text.secondary">
                            {formatDate(message.timestamp)}
                          </Typography>
                        </Box>
                        <ListItemText
                          primary={
                            <Typography
                              component="div"
                              variant="body1"
                              sx={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                              }}
                            >
                              {message.content}
                            </Typography>
                          }
                        />
                      </ListItem>
                      {index < (chat.messages?.length || 0) - 1 && (
                        <Divider component="li" />
                      )}
                    </React.Fragment>
                  ))}
                </List>
              </Paper>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        User Chat History
      </Typography>

      {error ? (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      ) : (
        <>
          <Paper sx={{ borderRadius: 2, mb: 3 }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="fullWidth"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab label={`General Chat (${generalChats.length})`} />
              <Tab label={`Assistant Chats (${assistantChats.length})`} />
            </Tabs>
          </Paper>

          <TabPanel value={tabValue} index={0}>
            {renderChatList(generalChats)}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {renderChatList(assistantChats)}
          </TabPanel>
        </>
      )}
    </Box>
  );
};

export default UserChatHistory;
