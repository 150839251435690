import axios from 'axios';

export interface SharePointFolder {
  name: string;
  path: string;
  created: string;
  modified: string;
}

export interface SharePointFile {
  name: string;
  path: string;
  size: number;
  modified: string;
}

export const sharepointApi = {
  getLibraryRoot: async (): Promise<string> => {
    const response = await axios.get('/api/v1/sharepoint/library-root', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  listFolders: async (path: string = '_'): Promise<SharePointFolder[]> => {
    const response = await axios.get(`/api/v1/sharepoint/folders/${path}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  listFiles: async (path: string = '_'): Promise<SharePointFile[]> => {
    const response = await axios.get(`/api/v1/sharepoint/files/${path}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  }
};
