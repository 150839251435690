import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  useTheme,
  Divider,
  useMediaQuery,
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import {
  People as PeopleIcon,
  Chat as ChatIcon,
  Assistant as AssistantIcon,
  Message as MessageIcon,
} from '@mui/icons-material';
import apiService from '../../services/api';

interface AnalyticsData {
  totalUsers: number;
  activeUsers: number;
  totalConversations: number;
  conversationsPerBot: Array<{ bot: string; count: number }>;
  dailyConversations: number[];
  weekLabels: string[];
  dailyActiveUsers: number[];
  messageVolume: number[];
  assistantUsage: Array<{ assistant: string; messages: number }>;
  userEngagement: {
    daily: number;
    weekly: number;
    monthly: number;
  };
  messageTypes: {
    general: number;
    assistant: number;
  };
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const Analytics: React.FC = () => {
  const [data, setData] = useState<AnalyticsData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const chartHeight = isMobile ? 200 : 300;

  useEffect(() => {
    loadAnalytics();
  }, []);

  const loadAnalytics = async () => {
    try {
      setLoading(true);
      const response = await apiService.analytics.getOverview();
      setData(response);
    } catch (err: any) {
      setError('Failed to load analytics data');
      console.error('Error loading analytics:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: isMobile ? 1 : 2 }}>
        {error}
      </Alert>
    );
  }

  if (!data) return null;

  const StatCard = ({ title, value, icon: Icon, color }: any) => (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Icon sx={{ color, mr: 1 }} />
          <Typography variant="h6" color="textSecondary" fontSize={isMobile ? '1rem' : '1.25rem'}>
            {title}
          </Typography>
        </Box>
        <Typography variant="h4" fontSize={isMobile ? '1.75rem' : '2.125rem'}>
          {value}
        </Typography>
      </CardContent>
    </Card>
  );

  return (
    <Box p={0}>
      <Typography 
        variant="h4" 
        gutterBottom 
        fontSize={isMobile ? '1.75rem' : '2.125rem'}
        sx={{ px: isMobile ? 2 : 3, pt: isMobile ? 2 : 3 }}
      >
        Analytics Dashboard
      </Typography>

      {/* Overview Stats */}
      <Grid container spacing={isMobile ? 1 : 2} sx={{ px: isMobile ? 1 : 3, mb: isMobile ? 1 : 3 }}>
        <Grid item xs={6} sm={6} md={3}>
          <StatCard
            title="Total Users"
            value={data.totalUsers}
            icon={PeopleIcon}
            color={theme.palette.primary.main}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <StatCard
            title="Active Users"
            value={data.activeUsers}
            icon={PeopleIcon}
            color={theme.palette.success.main}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <StatCard
            title="Total Chats"
            value={data.totalConversations}
            icon={ChatIcon}
            color={theme.palette.info.main}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <StatCard
            title="Messages"
            value={data.messageVolume[data.messageVolume.length - 1]}
            icon={MessageIcon}
            color={theme.palette.warning.main}
          />
        </Grid>
      </Grid>

      {/* User Activity */}
      <Grid container spacing={isMobile ? 1 : 2} sx={{ px: isMobile ? 1 : 3, mb: isMobile ? 1 : 3 }}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom fontSize={isMobile ? '1rem' : '1.25rem'}>
              Daily Active Users
            </Typography>
            <Box sx={{ width: '100%', height: chartHeight }}>
              <ResponsiveContainer>
                <LineChart data={data.weekLabels.map((label, index) => ({
                  name: label,
                  users: data.dailyActiveUsers[index]
                }))}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" tick={{ fontSize: isMobile ? 12 : 14 }} />
                  <YAxis tick={{ fontSize: isMobile ? 12 : 14 }} />
                  <Tooltip />
                  <Legend wrapperStyle={{ fontSize: isMobile ? 12 : 14 }} />
                  <Line type="monotone" dataKey="users" stroke={theme.palette.primary.main} />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom fontSize={isMobile ? '1rem' : '1.25rem'}>
              User Engagement
            </Typography>
            <Box height={chartHeight} display="flex" flexDirection="column" justifyContent="space-around">
              <Box>
                <Typography variant="subtitle1" color="textSecondary" fontSize={isMobile ? '0.875rem' : '1rem'}>
                  Daily Active Users
                </Typography>
                <Typography variant="h4" fontSize={isMobile ? '1.5rem' : '2rem'}>
                  {data.userEngagement.daily}
                </Typography>
              </Box>
              <Divider />
              <Box>
                <Typography variant="subtitle1" color="textSecondary" fontSize={isMobile ? '0.875rem' : '1rem'}>
                  Weekly Active Users
                </Typography>
                <Typography variant="h4" fontSize={isMobile ? '1.5rem' : '2rem'}>
                  {data.userEngagement.weekly}
                </Typography>
              </Box>
              <Divider />
              <Box>
                <Typography variant="subtitle1" color="textSecondary" fontSize={isMobile ? '0.875rem' : '1rem'}>
                  Monthly Active Users
                </Typography>
                <Typography variant="h4" fontSize={isMobile ? '1.5rem' : '2rem'}>
                  {data.userEngagement.monthly}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Chat Analytics */}
      <Grid container spacing={isMobile ? 1 : 2} sx={{ px: isMobile ? 1 : 3, mb: isMobile ? 1 : 3 }}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom fontSize={isMobile ? '1rem' : '1.25rem'}>
              Message Volume Trend
            </Typography>
            <Box sx={{ width: '100%', height: chartHeight }}>
              <ResponsiveContainer>
                <BarChart data={data.weekLabels.map((label, index) => ({
                  name: label,
                  messages: data.messageVolume[index]
                }))}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" tick={{ fontSize: isMobile ? 12 : 14 }} />
                  <YAxis tick={{ fontSize: isMobile ? 12 : 14 }} />
                  <Tooltip />
                  <Legend wrapperStyle={{ fontSize: isMobile ? 12 : 14 }} />
                  <Bar dataKey="messages" fill={theme.palette.primary.main} />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom fontSize={isMobile ? '1rem' : '1.25rem'}>
              Message Distribution
            </Typography>
            <Box sx={{ width: '100%', height: chartHeight }}>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={[
                      { name: 'General Chat', value: data.messageTypes.general },
                      { name: 'Assistant Chat', value: data.messageTypes.assistant }
                    ]}
                    cx="50%"
                    cy="50%"
                    innerRadius={isMobile ? 40 : 60}
                    outerRadius={isMobile ? 70 : 100}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    label
                  >
                    {[0, 1].map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend wrapperStyle={{ fontSize: isMobile ? 12 : 14 }} />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Assistant Usage */}
      <Grid container spacing={isMobile ? 1 : 2} sx={{ px: isMobile ? 1 : 3, pb: isMobile ? 1 : 3 }}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom fontSize={isMobile ? '1rem' : '1.25rem'}>
              Assistant Usage
            </Typography>
            <Box sx={{ width: '100%', height: chartHeight }}>
              <ResponsiveContainer>
                <BarChart data={data.assistantUsage}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="assistant" 
                    tick={{ fontSize: isMobile ? 12 : 14 }}
                    angle={isMobile ? -45 : 0}
                    textAnchor={isMobile ? 'end' : 'middle'}
                    height={isMobile ? 60 : 30}
                  />
                  <YAxis tick={{ fontSize: isMobile ? 12 : 14 }} />
                  <Tooltip />
                  <Legend wrapperStyle={{ fontSize: isMobile ? 12 : 14 }} />
                  <Bar dataKey="messages" fill={theme.palette.secondary.main} />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Analytics;
