import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  People as PeopleIcon,
  Analytics as AnalyticsIcon,
  SmartToy as AssistantIcon,
} from '@mui/icons-material';

const AdminNav: React.FC = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const menuItems = [
    { text: 'Analytics', icon: <AnalyticsIcon />, path: '/admin' },
    { text: 'Users', icon: <PeopleIcon />, path: '/admin/users' },
    { text: 'Assistants', icon: <AssistantIcon />, path: '/admin/assistants' },
  ];

  if (isMobile) {
    return (
      <BottomNavigation
        value={location.pathname}
        sx={{
          width: '100%',
          position: 'fixed',
          bottom: 0,
          borderTop: 1,
          borderColor: 'divider',
          zIndex: theme.zIndex.appBar,
        }}
      >
        {menuItems.map((item) => (
          <BottomNavigationAction
            key={item.text}
            label={item.text}
            icon={item.icon}
            value={item.path}
            component={Link}
            to={item.path}
          />
        ))}
      </BottomNavigation>
    );
  }

  return (
    <AppBar position="static" color="default" elevation={1}>
      <Toolbar>
        {menuItems.map((item) => (
          <Button
            key={item.text}
            component={Link}
            to={item.path}
            startIcon={item.icon}
            color={location.pathname === item.path ? "primary" : "inherit"}
            sx={{ mr: 2 }}
          >
            {item.text}
          </Button>
        ))}
      </Toolbar>
    </AppBar>
  );
};

export default AdminNav;
